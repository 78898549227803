<template>
    <v-container fluid>
        <v-form @submit.prevent="saveInvoice">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{heading}}</v-subheader>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="country_recipient" rules="required" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="country_recipient" :items="countryItemsRecipient"
                                                    :error="!valid" :error-messages="errors"
                                                    item-text="name" item-value="uuid" prepend-icon="mdi-web"
                                                    :loading="loadingCountriesRecipient" :disabled="loading"
                                                    :label="$t('country_recipient')" @click:clear="clearCountriesRecipient"
                                                    outlined
                                                    rounded
                                                    dense
                                                    color="primary" return-object clearable >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" sm="6" cols="12" >
                                <ValidationProvider  ref="city_recipient" rules="required" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="city_recipient" :items="cityItemsRecipient"
                                                    :error="!valid" :error-messages="errors"
                                                    item-text="name" item-value="uuid" prepend-icon="mdi-city"
                                                    :loading="loadingCitiesRecipient" :disabled="loading || !country_recipient"
                                                    :label="$t('city_recipient')" @click:clear="clearCitiesRecipient"
                                                    outlined
                                                    rounded
                                                    dense
                                                    color="primary" return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="region_recipient" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="region_recipient" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                  :label="$t('region_recipient')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="address_recipient" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="address_recipient" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                  :label="$t('address_recipient')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="name_recipient" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="name_recipient" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-courier"
                                                  :label="$t('name_recipient')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="phone_recipient" rules="required|max:20"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phone_recipient" type="tel"
                                                  :error-messages="errors" :error="!valid"
                                                  :disabled="loading" prepend-icon="mdi-phone"
                                                  :label="$t('phone_recipient')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="weight" rules="required|min_value:0.3"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="weight" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-weight-gram"
                                                  :label="$t('weight')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="items" rules="required|numeric|min_value:1|max_value:100"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="items" type="number"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-weight-gram"
                                                  :label="$t('number_seats')" color="primary"
                                                  outlined
                                                  rounded
                                                  dense
                                                  step="1"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="delivery_mode" rules="required" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="delivery_mode" :items="deliveryModeItems"
                                                    :error="!valid" :error-messages="errors"
                                                    item-text="name" item-value="uuid" prepend-icon="mdi-check-bold"
                                                    :loading="loadingDeliveryMode" :disabled="loading"
                                                    :label="$t('delivery_mode')" @click:clear="clearDeliveryMode"
                                                    outlined
                                                    rounded
                                                    dense
                                                    color="primary" return-object clearable >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" sm="6" cols="12">
                                <ValidationProvider ref="payer_type" rules="required" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="payer_type" :items="payerTypeItems"
                                                    :error="!valid" :error-messages="errors"
                                                    item-text="name" item-value="uuid" prepend-icon="mdi-check-bold"
                                                    :loading="loadingPayerType" :disabled="loading"
                                                    :label="$t('payer_type')" @click:clear="clearPayerType"
                                                    outlined
                                                    rounded
                                                    dense
                                                    color="primary" return-object clearable >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0 my-0" cols="12">

                                <ValidationProvider ref="payment_method" rules="required" v-slot="{ errors, valid }">
                                    <fieldset class="px-3 mb-5 outlined
                                               rounded
                                               dense" >
                                        <legend>{{ $t('payment_type') }}</legend>


                                <v-radio-group row v-model="payment_method" class="pt-1 mt-1"
                                               :error="!valid" :error-messages="errors"
                                               :loading="loading" :disabled="loading"
                                               outlined
                                               rounded
                                               dense
                                               color="primary"
                                >
                                    <v-radio key="1" :label="$t('cash')" value="1"></v-radio>
                                    <v-radio key="2" :label="$t('by_account')" value="2"></v-radio>
                                </v-radio-group>
                                    </fieldset>
                                </ValidationProvider>

                            </v-col>


                                <v-col class="py-0" cols="12" sm="4" md="4">
                                    <v-checkbox class="py-0 my-0"
                                        v-model="danger"
                                        :label="$t('danger')"
                                        :value="!!danger"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4" md="4">
                                    <v-checkbox class="py-0 my-0 "
                                                v-model="document"
                                        :label="$t('document')"
                                        :value="!!document"

                                    ></v-checkbox>
                                </v-col>
                                <v-col class="py-0 " cols="12" sm="4" md="4">
                                    <v-checkbox class="py-0 my-0"
                                        v-model="notification"
                                        :label="$t('notification')"
                                        :value=" !!notification"
                                    ></v-checkbox>
                                </v-col>


                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="description " rules="min:3|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="description " type="text"
                                                :error="!valid" :error-messages="errors"
                                                :disabled="loading" :label="$t('order_description')"
                                                rows="5" color="primary" auto-grow
                                                outlined
                                                rounded
                                                dense
                                                clearable>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce";



    export default {
        name: 'OrderForm',
        components: {
            ValidationProvider,
            ValidationObserver,

        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                tab: 0,
                loading: false,

                create: false,
                language: null,
                payment_method: null,
                date: null,
                number: null,
                danger: false,
                document: false,
                notification: false,

                loadingDeliveryMode: false,
                delivery_mode: null,
                deliveryModeItems: [],

                loadingPayerType: false,
                payer_type: null,
                payerTypeItems: [],

                description : null,
                weight : null,
                items : null,
                order_uuid: null,
                address_recipient: null,
                country_recipient: null,
                countryItemsRecipient: [],
                loadingCountriesRecipient: false,
                city_recipient: null,
                cityItemsRecipient: [],
                loadingCitiesRecipient: false,
                phone_recipient: null,
                name_recipient: null,
                region_recipient : null,

                exist_translations: {},
                all_translations: true,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        watch: {
            country_recipient: debounce(function (val) {
                this.getCitiesRecipient(val)
            }, 500),

        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.checkCreate()
            await this.getCountriesRecipient()
            await this.getPayerType()
            await this.getDeliveryMode()

        },
        methods: {
            checkCreate() {
                if (this.$route.name === 'invoice.create') {
                    this.create = true
                    this.heading = this.$t('invoice_creation')
                } else {

                    if (this.$route.params.id) {
                        this.heading = this.$t('invoice_editing')
                        this.getInvoice(this.$route.params.id)
                    }
                }

            },


            async getInvoice(id) {
                var _this = this
                this.progress = 0
                this.loading = true
                this.id = id
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`courier/invoice/${id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.description = res.body.data.description
                        this.address_recipient = res.body.data.address_recipient
                        this.phone_recipient = res.body.data.phone_recipient
                        this.country_recipient = res.body.data.country_recipient
                        this.city_recipient = res.body.data.city_recipient
                        this.region_recipient = res.body.data.region_recipient
                        this.name_recipient = res.body.data.name_recipient
                        this.weight = res.body.data.weight
                        this.order_uuid = res.body.data.order_uuid
                        this.payer_type = res.body.data.payer_type
                        this.delivery_mode = res.body.data.delivery_mode
                        this.items = res.body.data.items
                        this.payment_method = res.body.data.payment_method
                        this.date = res.body.data.date_send
                        this.number = res.body.data.number
                        this.danger = res.body.data.danger
                        this.document = res.body.data.document
                        this.notification = res.body.data.notification
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_order'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveInvoice() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()






                if (this.language) {
                    formData.append('language', this.language)
                }

                if (this.payment_method) {
                    formData.append('payment_method', this.payment_method)
                }

                formData.append('date', this.date)
                formData.append('number', this.number)

                if (this.danger) {
                    formData.append('danger', 1)
                }
                if (this.document) {
                    formData.append('document', 1)
                }
                if (this.notification) {
                    formData.append('notification', 1)
                }

                if (this.items) {
                    formData.append('items', this.items)
                }


                if (this.weight) {
                    formData.append('weight', this.weight)
                }
                if (this.description) {
                    formData.append('description', this.description)
                }


                if (this.name_recipient) {
                    formData.append('name_recipient', this.name_recipient)
                }
                if (this.phone_recipient) {
                    formData.append('phone_recipient', this.phone_recipient)
                }
                if (this.address_recipient) {
                    formData.append('address_recipient', this.address_recipient)
                }
                if (this.region_recipient) {
                    formData.append('region_recipient', this.region_recipient)
                }

                if (this.country_recipient) {
                    if (this.country_recipient.uuid) {
                        formData.append('country_recipient', this.country_recipient.uuid)
                    } else {
                        formData.append('country_recipient', this.country_recipient)
                    }
                }

                if (this.city_recipient) {
                    if (this.city_recipient.uuid) {
                        formData.append('city_recipient', this.city_recipient.uuid)
                    } else {
                        formData.append('city_recipient', this.city_recipient)
                    }
                }

                if (this.payer_type) {
                    if (this.payer_type.uuid) {
                        formData.append('payer_type', this.payer_type.uuid)
                    } else {
                        formData.append('payer_type', this.payer_type)
                    }
                }

                if (this.delivery_mode) {
                    if (this.delivery_mode.uuid) {
                        formData.append('delivery_mode', this.delivery_mode.uuid)
                    } else {
                        formData.append('delivery_mode', this.delivery_mode)
                    }
                }


                if (this.$route.name === 'invoice.edit') {
                    formData.append('order_uuid', this.order_uuid)
                }
                else{
                 //   formData.append('order_uuid', this.$route.params.id)
                }

                await this.$http
                    .put(`courier/invoice/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('invoice_has_been_updated'))
                      //  //this.$router.push({
                       //     name: 'orders'
                      //  })

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            async getCitiesRecipient(str) {
                if (str) {
                    this.loadingCitiesRecipient = true
                    let params = {};
                    params.filter = 'search';
                    if (this.country_recipient) {
                        if (this.country_recipient.uuid) {
                            params.country = this.country_recipient.uuid;
                        } else {
                            params.country = this.country_recipient;
                        }
                    }
                    if (this.lang) {
                        params.language = this.lang
                    }
                    await this.$http
                        .get("courier/city", {
                            params: params,
                        })
                        .then(res => {

                            this.cityItemsRecipient = res.body.data


                        })
                        .catch(err => {
                            this.cityItemsRecipient = []
                            this.$toastr.error(this.$t('failed_to_get_list_cities'))
                        })
                        .finally(end => {
                            this.loadingCitiesRecipient = false
                        })
                }
            },

            async getPayerType() {
                this.loadingPayerType = true
                let params = {};
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/payer_type", {
                        params: params,
                    })
                    .then(res => {
                        this.payerTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.payerTypeItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_payer_types'))
                    })
                    .finally(end => {
                        this.loadingPayerType = false
                    })
            },
            async getDeliveryMode() {
                this.loadingDeliveryMode = true
                let params = {};
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/delivery_mode", {
                        params: params,
                    })
                    .then(res => {
                        this.deliveryModeItems = res.body.data
                    })
                    .catch(err => {
                        this.deliveryModeItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_delivery_modes'))
                    })
                    .finally(end => {
                        this.loadingDeliveryMode = false
                    })
            },
            async getCountriesRecipient() {
                this.loadingCountriesRecipient = true
                let params = {};
                params.filter = 'search';
                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/country", {
                        params: params,
                    })
                    .then(res => {
                        this.countryItemsRecipient = res.body.data
                    })
                    .catch(err => {
                        this.countryItemsRecipient = []
                        this.$toastr.error(this.$t('failed_to_get_list_countries'))
                    })
                    .finally(end => {
                        this.loadingCountriesRecipient = false
                    })
            },

            clearDeliveryMode() {
                this.delivery_mode = null
                this.deliveryModeItems = []
            },
            clearPayerType() {
                this.payer_type = null
                this.payerTypeItems = []
            },
            clearCountriesRecipient() {
                this.country_recipient = null
                this.city_recipient = null
                this.cityItemsRecipient = []
            },
            clearCitiesRecipient() {
                this.city_recipient = null
            },



        }
    }
</script>
