var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveInvoice.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"headline"},[_vm._v(_vm._s(_vm.heading))])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"country_recipient",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.countryItemsRecipient,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-web","loading":_vm.loadingCountriesRecipient,"disabled":_vm.loading,"label":_vm.$t('country_recipient'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":""},on:{"click:clear":_vm.clearCountriesRecipient},model:{value:(_vm.country_recipient),callback:function ($$v) {_vm.country_recipient=$$v},expression:"country_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"city_recipient",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.cityItemsRecipient,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-city","loading":_vm.loadingCitiesRecipient,"disabled":_vm.loading || !_vm.country_recipient,"label":_vm.$t('city_recipient'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":"","autocomplete":"off"},on:{"click:clear":_vm.clearCitiesRecipient},model:{value:(_vm.city_recipient),callback:function ($$v) {_vm.city_recipient=$$v},expression:"city_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"region_recipient",attrs:{"rules":"min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-map-marker-radius","label":_vm.$t('region_recipient'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.region_recipient),callback:function ($$v) {_vm.region_recipient=$$v},expression:"region_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"address_recipient",attrs:{"rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-map-marker-radius","label":_vm.$t('address_recipient'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.address_recipient),callback:function ($$v) {_vm.address_recipient=$$v},expression:"address_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"name_recipient",attrs:{"rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-courier","label":_vm.$t('name_recipient'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.name_recipient),callback:function ($$v) {_vm.name_recipient=$$v},expression:"name_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"phone_recipient",attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"tel","error-messages":errors,"error":!valid,"disabled":_vm.loading,"prepend-icon":"mdi-phone","label":_vm.$t('phone_recipient'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.phone_recipient),callback:function ($$v) {_vm.phone_recipient=$$v},expression:"phone_recipient"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"weight",attrs:{"rules":"required|min_value:0.3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-weight-gram","label":_vm.$t('weight'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"items",attrs:{"rules":"required|numeric|min_value:1|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-weight-gram","label":_vm.$t('number_seats'),"color":"primary","outlined":"","rounded":"","dense":"","step":"1","clearable":""},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"delivery_mode",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.deliveryModeItems,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-check-bold","loading":_vm.loadingDeliveryMode,"disabled":_vm.loading,"label":_vm.$t('delivery_mode'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":""},on:{"click:clear":_vm.clearDeliveryMode},model:{value:(_vm.delivery_mode),callback:function ($$v) {_vm.delivery_mode=$$v},expression:"delivery_mode"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('ValidationProvider',{ref:"payer_type",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.payerTypeItems,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-check-bold","loading":_vm.loadingPayerType,"disabled":_vm.loading,"label":_vm.$t('payer_type'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":""},on:{"click:clear":_vm.clearPayerType},model:{value:(_vm.payer_type),callback:function ($$v) {_vm.payer_type=$$v},expression:"payer_type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"payment_method",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('fieldset',{staticClass:"px-3 mb-5 outlined\n                                           rounded\n                                           dense"},[_c('legend',[_vm._v(_vm._s(_vm.$t('payment_type')))]),_c('v-radio-group',{staticClass:"pt-1 mt-1",attrs:{"row":"","error":!valid,"error-messages":errors,"loading":_vm.loading,"disabled":_vm.loading,"outlined":"","rounded":"","dense":"","color":"primary"},model:{value:(_vm.payment_method),callback:function ($$v) {_vm.payment_method=$$v},expression:"payment_method"}},[_c('v-radio',{key:"1",attrs:{"label":_vm.$t('cash'),"value":"1"}}),_c('v-radio',{key:"2",attrs:{"label":_vm.$t('by_account'),"value":"2"}})],1)],1)]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-checkbox',{staticClass:"py-0 my-0",attrs:{"label":_vm.$t('danger'),"value":!!_vm.danger},model:{value:(_vm.danger),callback:function ($$v) {_vm.danger=$$v},expression:"danger"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-checkbox',{staticClass:"py-0 my-0 ",attrs:{"label":_vm.$t('document'),"value":!!_vm.document},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1),_c('v-col',{staticClass:"py-0 ",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-checkbox',{staticClass:"py-0 my-0",attrs:{"label":_vm.$t('notification'),"value":!!_vm.notification},model:{value:(_vm.notification),callback:function ($$v) {_vm.notification=$$v},expression:"notification"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"description ",attrs:{"rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"label":_vm.$t('order_description'),"rows":"5","color":"primary","auto-grow":"","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.description ),callback:function ($$v) {_vm.description =$$v},expression:"description "}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }